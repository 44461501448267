<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2">
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >10</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Publicación
          <span class="font-normal">Exitosa</span>
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center mt-8 mb-5 md:mt-16 md:mb-12">
      <img class="w-14 md:w-16" src="@/assets/images/iconsuccessful.svg" />
    </div>
    <div
      class="grid grid-cols-1 gap-y-6 mx-8 mb-6 lg:gap-y-9 md:mb-16 md:mx-20 lg:mx-40 xl:mx-80"
    >
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p class="font-dinpro text-white text-sm text-left lg:text-2xl">
          Su publicación ha sido procesada exitosamente
        </p>
      </div>
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p class="font-dinpro text-white text-sm text-left lg:text-2xl">
          Te notificaremos en máximo 5 días Calendario, una vez TU SUBASTA sea
          revisada y aprobada por nuestro equipo.
        </p>
      </div>
    </div>
    <div
      class="bg-agrogo-yellow-1 h-0.5 mb-6 mx-4 md:mb-10 md:mx-20 lg:mx-40 xl:mx-80"
    ></div>
    <div class="grid grid-cols-2 gap-x-4 mx-5 pb-8 md:gap-x-7 md:pb-20">
      <div class="flex items-center justify-end">
        <button class="btn-login md:max-w-236">
          <span class="font-nexa font-bold text-agrogo-green-2"
            >Publicar otra Subasta</span
          >
        </button>
      </div>
      <div class="flex items-center justify-start">
        <button class="btn-login md:max-w-236">
          <span class="font-nexa font-bold text-agrogo-green-2"
            >Ir a Subastas</span
          >
        </button>
      </div>
    </div>
    <div class="bg-agrogo-yellow-1 h-2"></div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
};
</script>
